"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationToJSON = exports.RegistrationFromJSONTyped = exports.RegistrationFromJSON = exports.instanceOfRegistration = void 0;
var runtime_1 = require("../runtime");
var Producer_1 = require("./Producer");
/**
 * Check if a given object implements the Registration interface.
 */
function instanceOfRegistration(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfRegistration = instanceOfRegistration;
function RegistrationFromJSON(json) {
    return RegistrationFromJSONTyped(json, false);
}
exports.RegistrationFromJSON = RegistrationFromJSON;
function RegistrationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'producer': !(0, runtime_1.exists)(json, 'producer') ? undefined : (0, Producer_1.ProducerFromJSON)(json['producer']),
        'productionDescription': !(0, runtime_1.exists)(json, 'productionDescription') ? undefined : json['productionDescription'],
    };
}
exports.RegistrationFromJSONTyped = RegistrationFromJSONTyped;
function RegistrationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'producer': (0, Producer_1.ProducerToJSON)(value.producer),
        'productionDescription': value.productionDescription,
    };
}
exports.RegistrationToJSON = RegistrationToJSON;
